import { reactive, ref } from 'vue';
import CryptoJS from '@/utils/crypto-js';
import { useMessageStore } from '@/stores/message';
import { getMessageContent, addMessage, updateMessage } from './api.js';
import { deleteAnswer } from '../components/api';

import { showDialog } from 'vant';
export const useMessage = function (id) {
  const messageStore = useMessageStore();
  let API_KEY,API_SECRET,APPId
  // if (process.env.NODE_ENV !== 'production') {
  //    API_KEY = 'ade5f0e101da7ca8cc42178c461138e5';
  //    API_SECRET = 'YzhlNWQyNmVmY2M4Y2MyNjRjNDk0MDBm';
  //    APPId = '2b990897';
  // }else{
     API_KEY = 'e9c71060f3cf3c04d3dce345b2db9f0a';
     API_SECRET = 'ODk1NjVhZDhkMWM3NjAyNTJmODE0ZGE3';
     APPId = 'f59c2dff';
  // }
 
  const total_res = ref('');
  let ttsWs = reactive({});
  const answerId = ref('');
  const showBottom = ref(true);

  let messageData = reactive({
    status: '',
    statusText: '',
  });
  let textData = ref([]);
  const reloadMessage = ref({});
  if (id) {
    answerId.value = answerId;
  }
  function init(id) {
    answerId.value = id;
    //获取会话记录
    getMessageContent({
      answerId: answerId.value,
    }).then((res) => {
      let arr = res.data.map((i) => {
        return {
          role: i.role,
          content: i.chatContent,
        };
      });
      textData.value = arr;
    });
  }
  function setAnswerId(id) {
    answerId.value = id;
  }
  //获取AI请求地址
  function getWebsocketUrl() {
    return new Promise((resolve) => {
      var apiKey = API_KEY;
      var apiSecret = API_SECRET;
      var url = 'wss://spark-api.xf-yun.com/v3.5/chat';
      var host = new URL(process.env.VUE_APP_API_URL).host;
      var date = new Date().toGMTString();
      var algorithm = 'hmac-sha256';
      var headers = 'host date request-line';
      var signatureOrigin = `host: ${host}\ndate: ${date}\nGET /v3.5/chat HTTP/1.1`;
      var signatureSha = CryptoJS.HmacSHA256(signatureOrigin, apiSecret);
      var signature = CryptoJS.enc.Base64.stringify(signatureSha);
      var authorizationOrigin = `api_key="${apiKey}", algorithm="${algorithm}", headers="${headers}", signature="${signature}"`;
      var authorization = btoa(authorizationOrigin);
      url = `${url}?authorization=${authorization}&date=${date}&host=${host}`;
      resolve(url);
    });
  }
  let textIndex = 0;
  let isOutputting = ref(false); // 判断当前是否正在输出
  let timer;
  //文字效果
  function outputMessage() {
    isOutputting.value = true;
    timer = setInterval(() => {
      if (textIndex < total_res.value.length) {
        const char = total_res.value.charAt(textIndex);
        textData.value[textData.value.length - 1].content += char; // 逐字输出，你可以修改这一步，将字符添加到你想要显示的地方（比如 HTML 元素中）
        textIndex++;
      } else {
        clearInterval(timer); // 当所有字符都显示完毕后清除定时器
        isOutputting.value = false;
        //   total_res.value = ''; // 清空完整消息内容，以便接收下一次返回的片段
      }
    }, 5); // 每隔100毫秒显示一个字符，你可以根据需要调整这个参数
  }

  // websocket接收数据的处理
  function result(resultData) {
    let jsonData = JSON.parse(resultData);
    if (jsonData.header.code == 0) {
      let text = jsonData.payload.choices.text[0].content;
      total_res.value = total_res.value + text;
    }
    // textData.value[textData.value.length - 1].content = total_res.value;
    if (!isOutputting.value) {
      outputMessage(); // 如果当前没有在输出，则开始逐字输出
    }
    textData.value[textData.value.length - 1].status = 'connect';
    setStatus('connect');
    // 提问失败
    if (jsonData.header.code !== 0) {
      textData.value[textData.value.length - 1].content = '抱歉，我还没有学习到关于这个话题的内容，无法提供相关信息。您可以选择其他问题，我将努力为您解答。';
      showBottom.value = false
      deleteAnswer({ answerId: answerId.value }).then(() => {})
      closedMessage();
      return;
    }
    if (jsonData.header.code === 0 && jsonData.header.status === 2) {
      closedMessage();
    }
  }
  function closedMessage() {
    ttsWs.close();
    textData.value[textData.value.length - 1].status = 'init';
    setStatus('init');
    if (reloadMessage.value.memberChatId) {
      editMessage();
    } else {
      addMessage({
        answerId: answerId.value,
        role: 'assistant',
        chatContent: total_res.value,
      }).then((res) => {
        textData.value[textData.value.length - 1].memberChatId =
          res.data.memberChatId;
      });
    }
  }
  function editMessage() {
    updateMessage({
      answerId: answerId.value,
      memberChatId: reloadMessage.value.memberChatId,
      role: 'assistant',
      chatContent: total_res.value,
    }).then((res) => {
      if (res.code === 1) {
        textData.value[textData.value.length-1].memberChatId = reloadMessage.value.memberChatId
        reloadMessage.value = {};
      }
    });
  }
  function addText(role, text, status) {
    return new Promise((reslove) => {
      if (role === 'user') {
        if(localStorage.getItem('reloadPage')){
          textData.value.push({
            role,
            content: text,
          });
          reslove(true);
        }else{
          addMessage({
            answerId: answerId.value,
            role,
            chatContent: text,
          }).then((res) => {
            if (res.code === 500) {
              showDialog({ title: '提示', message: res.msg });
              setStatus('init');
  
              reslove(false);
            } else {
              textData.value.push({
                role,
                content: text,
              });
              reslove(true);
            }
          });
        }
      } else {
        textData.value.push({
          role,
          content: text,
          status,
        });
        reslove(true);
      }
    });
  }

  function onWillStatusChange(oldStatus, status) {
    // 可以在这里进行页面中一些交互逻辑处理：按钮交互等
    messageStore.status = status;
  }
  // 修改状态
  function setStatus(status) {
    onWillStatusChange && onWillStatusChange(messageData.status, status);
    messageData.status = status;
  }
  async function sendMessage({ text, isReload }) {
    setStatus('connect');
    textIndex = 0;
    total_res.value = '';
    if (!isReload) {
      let flag = await addText('user', text);
      if (!flag) {
        return;
      }
    }
    getWebsocketUrl().then((url) => {
      if ('WebSocket' in window) {
        ttsWs = new WebSocket(url);
      } else {
        alert('浏览器不支持使用此功能');
        return;
      }
      ttsWs.onopen = () => {
        webSocketSend(text);
      };
      ttsWs.onmessage = (e) => {
        setStatus('connect');
        result(e.data);
      };
      ttsWs.onerror = () => {
        console.error(`详情查看：${encodeURI(url.replace('wss:', 'https:'))}`);
        this.setStatus('error');
      };
      ttsWs.onclose = () => {
        setStatus('init');
      };
    });
  }
  function reload() {
    if(localStorage.getItem('reloadPage')){
      localStorage.removeItem("reloadPage");
    }
    setTimeout(() => {
      if (textData.value[textData.value.length - 1].role === 'assistant') {
        reloadMessage.value = textData.value.pop();
        sendMessage({
          text: textData.value[textData.value.length - 1].content,
          isReload: true,
        });
      }
    }, 300);
  }
  function stop() {
    textData.value[textData.value.length - 1].status = 'init';
    setStatus('init');
    ttsWs.close();

    if (reloadMessage.value.memberChatId) {
      reloadMessage();
    } else {
      addMessage({
        answerId: answerId.value,
        role: 'assistant',
        chatContent: textData.value[textData.value.length - 1].content,
      }).then((res) => {
        textData.value[textData.value.length - 1].memberChatId =
          res.data.memberChatId;
      });
    }
    clearInterval(timer);
  }
  // websocket发送数据
  function webSocketSend() {
    var params = {
      header: {
        app_id: APPId,
        uid: 'fd3f47e4-d',
      },
      parameter: {
        chat: {
          domain: 'generalv2',
          temperature: 0.8,
          max_tokens: 2048,
        },
      },
      payload: {
        message: {
          text: textData.value,
        },
      },
    };
    ttsWs.send(JSON.stringify(params));
    addText('assistant', '', 'loading');
    setStatus('loading');
  }
  return {
    sendMessage,
    total_res,
    ...messageData,
    stop,
    init,
    setAnswerId,
    answerId,
    textData,
    isOutputting,
    reload,
    showBottom
  };
};
