import { post } from '@/utils/http'
export const getMessageContent = function (data) {
    return post({
        url: "/app/xfai/selectAnswerChatList",
        data
    })
}

export const addMessage = function (data) {
    return post({
        url: "/app/xfai/addMemberChat",
        data
    })
}

export const updateMessage = function (data) {
    return post({
        url: "/app/xfai/updateMemberChat",
        data
    })
}

